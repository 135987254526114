import * as React from 'react';
import './TravelView.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class TravelView extends React.Component<any, any> {

    public render() {

        return (
            <div className="travel-fill">
                <Container>
                    <br />
                    <Row >
                        <Col>
                            <h1 className="travel-header">Travel Details</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="align-content">
                            <h3 className="travel-text">Getting There</h3>
                            <Row>
                                <Col>
                                    <h4>By Air</h4>
                                    <p>
                                        If you're flying to our wedding, you will be flying into SeaTac Airport. From there, you can take an Uber/Lyft or the Light Rail to get into the city.
                                        Ubers/Lyfts will take about 30 minutes to get to downtown Seattle, but can cost upwards of $50-60 to and from the airport. The Light Rail will take about 45 minutes to an hour from the airport to downtown, but only costs about $3.
                                        We strongly recommend an Uber/Lyft if you are staying in the downtown area to get to and from the Welcome Party and the Wedding Venue. 
                                        If you're in a hotel or AirBNB outside of downtown, Uber/Lyft is still probably the way to go so you can enjoy yourself the entire night! 
                                        Feel free to ask us for advice for your specific situation!
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>By Land</h4>
                                    <p>If you're driving, our venue is in the SoDo neighborhood (South of the Dome/South of Downtown). But please keep in mind that there is very limited parking at and around our venue. There will also be an open bar, so please Uber/Lyft so you can join the fun!</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>By Sea</h4>
                                    <p>Ferries are a big part of transportation in and around Seattle! If you're staying somewhere across the water, please plan to get into downtown early on the Big Day. We would feel awful if you missed the ceremony due to a late or overcrowded ferry! We would then recommend you take an Uber/Lyft to the venue if you are not driving.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="travel-text">Where to Stay</h3>
                            <Row>
                                <Col>
                                    <h4>Hotels</h4>
                                    <p>We do not have a hotel block. However, there are dozens of hotels in the downtown Seattle area that are just a short distance from our venue. Here are a few we recommend:
                                        <ul>
                                            <li><a className="hotel-link" href='https://www.fourseasons.com/seattle/' target="_blank" rel="noopener noreferrer">Four Seasons Seattle</a></li>
                                            <li><a className="hotel-link" href='https://www.fairmont.com/seattle/' target="_blank" rel="noopener noreferrer">Fairmont Olympic Hotel</a></li>
                                            <li><a className="hotel-link" href='https://www.thompsonhotels.com/hotels/washington/seattle/thompson-seattle' target="_blank" rel="noopener noreferrer">Thompson Seattle</a></li>
                                            <li><a className="hotel-link" href='https://www.hotel1000seattle.com/' target="_blank" rel="noopener noreferrer">Hotel 1000</a></li>
                                            <li><a className="hotel-link" href='https://www.marriott.com/hotels/travel/seawi-the-westin-seattle/' target="_blank" rel="noopener noreferrer">The Westin Seattle</a></li>
                                            <li><a className="hotel-link" href='https://www.hyatt.com/en-US/hotel/washington/hyatt-at-olive-8/seahs' target="_blank" rel="noopener noreferrer">Hyatt at Olive 8</a></li>
                                        </ul>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>AirBNB or VRBO</h4>
                                    <p>There are tons of AirBNBs around the city that would be great for groups! We recommend that you ensure your rental has air conditioning in case it is hot the weekend you visit. Here are some neighborhoods that we recommend:
                                        <ul>
                                            <li>Queen Anne (<a className="rental-link" href='https://www.airbnb.com/s/Queen-Anne--Seattle--WA/homes?adults=2&place_id=ChIJS04boGsVkFQR9Z2OSenfW94&refinement_paths%5B%5D=%2Fhomes&checkin=2022-08-12&checkout=2022-08-14' target="_blank" rel="noopener noreferrer">Airbnb</a>, <a className="rental-link" href='https://www.vrbo.com/search/keywords:queen-anne-seattle-washington-united-states-of-america/arrival:2022-08-12/departure:2022-08-14/minNightlyPrice/0?filterByTotalPrice=true&petIncluded=false&ssr=true&adultsCount=2&childrenCount=0' target="_blank" rel="noopener noreferrer">Vrbo</a>)</li>
                                            <li>University District (<a className="rental-link" href='https://www.airbnb.com/s/University-District--Seattle--WA/homes?adults=2&place_id=ChIJDxOEC4wUkFQRJmbHppH7LE0&refinement_paths%5B%5D=%2Fhomes&checkin=2022-08-12&checkout=2022-08-14' target="_blank" rel="noopener noreferrer">Airbnb</a>, <a className="rental-link" href='https://www.vrbo.com/search/keywords:university-district-seattle-washington-united-states-of-america/arrival:2022-08-12/departure:2022-08-14/minNightlyPrice/0?filterByTotalPrice=true&petIncluded=false&ssr=true&adultsCount=2&childrenCount=0' target="_blank" rel="noopener noreferrer">Vrbo</a>)</li>
                                            <li>Ballard (<a className="rental-link" href='https://www.airbnb.com/s/Ballard--Seattle--WA/homes?adults=2&place_id=ChIJgahdetUVkFQRnPTTCqyAdtA&refinement_paths%5B%5D=%2Fhomes&checkin=2022-08-12&checkout=2022-08-14' target="_blank" rel="noopener noreferrer">Airbnb</a>, <a className="rental-link" href='https://www.vrbo.com/search/keywords:ballard-seattle-washington-united-states-of-america/arrival:2022-08-12/departure:2022-08-14/minNightlyPrice/0?filterByTotalPrice=true&petIncluded=false&ssr=true&adultsCount=2&childrenCount=0' target="_blank" rel="noopener noreferrer">Vrbo</a>)</li>
                                            <li>Fremont (<a className="rental-link" href='https://www.airbnb.com/s/Fremont--Seattle--WA/homes?adults=2&place_id=ChIJ1WmlZawVkFQRmE1TlcKlxaI&refinement_paths%5B%5D=%2Fhomes&checkin=2022-08-12&checkout=2022-08-14' target="_blank" rel="noopener noreferrer">Airbnb</a>, <a className="rental-link" href='https://www.vrbo.com/search/keywords:fremont-seattle-washington-united-states-of-america/arrival:2022-08-12/departure:2022-08-14/minNightlyPrice/0?filterByTotalPrice=true&petIncluded=false&ssr=true&adultsCount=2&childrenCount=0' target="_blank" rel="noopener noreferrer">Vrbo</a>)</li>
                                            <li>Wallingford (<a className="rental-link" href='https://www.airbnb.com/s/Wallingford--Seattle--WA/homes?adults=2&place_id=ChIJQ3e3vlcUkFQRvvw4RMHzUjM&refinement_paths%5B%5D=%2Fhomes&checkin=2022-08-12&checkout=2022-08-14' target="_blank" rel="noopener noreferrer">Airbnb</a>, <a className="rental-link" href='https://www.vrbo.com/search/keywords:wallingford-seattle-washington-united-states-of-america/arrival:2022-08-12/departure:2022-08-14?adultsCount=2&petIncluded=false' target="_blank" rel="noopener noreferrer">Vrbo</a>)</li>
                                            <li>Capitol Hill (<a className="rental-link" href='https://www.airbnb.com/s/Capitol-Hill--Seattle--WA/homes?adults=2&place_id=ChIJYWHIVygVkFQRhVHCu8Z9SO8&refinement_paths%5B%5D=%2Fhomes&checkin=2022-08-12&checkout=2022-08-14' target="_blank" rel="noopener noreferrer">Airbnb</a>, <a className="rental-link" href='https://www.vrbo.com/search/keywords:capitol-hill-seattle-washington-united-states-of-america/arrival:2022-08-12/departure:2022-08-14/minNightlyPrice/0?filterByTotalPrice=true&petIncluded=false&ssr=true&adultsCount=2&childrenCount=0' target="_blank" rel="noopener noreferrer">Vrbo</a>)</li>
                                        </ul>
                                    </p>
                                    <p>Want an authentically "So Seattle" experience? You can also stay on the water! There are several AirBNBs on houseboats in Lake Union (Act fast! These are very difficult to book, even years in advance.).</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}