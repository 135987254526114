import * as React from 'react';
import './Footer.css';

export default class FooterComponent extends React.Component<any, any> {

    public render() {
        return (
            <footer className="footer">
                <div className="text-center footer-text">
                    © {new Date().getFullYear()} Copyright
                </div>
            </footer>
        )
    }

}