import * as React from 'react';
import './SeattleActivitiesView.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class SeattleActivitiesView extends React.Component<any, any> {

    public render() {

        return (
            <div className="seattle-fill">
                <Container>
                    <br />
                    <Row >
                        <Col>
                            <h1 className="seattle-header">Seattle Activities</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="seattle-text">Things to do in Seattle</h3>
                            <p>For a lot of our guests, it will be their first time in Seattle! We've put together a list of some of our favorite spots to check out while you're here. If you have any questions, or want additional recommendations on specific things, please reach out to Cooper and Shelby!</p>
                            <Row>
                                <Col>
                                    <h4>Seattle Locations and Activities</h4>
                                    <p>While you're in the city, we recommend going to:
                                        <ul>
                                            <li><a className="seattle-link" href='https://www.pikeplacemarket.org/' target="_blank" rel="noopener noreferrer">Pike Place Market</a></li>
                                            <li><a className="seattle-link" href='https://www.spaceneedle.com/' target="_blank" rel="noopener noreferrer">Space Needle</a></li>
                                            <li><a className="seattle-link" href='https://www.seattleartmuseum.org/visit/olympic-sculpture-park' target="_blank" rel="noopener noreferrer">Olympic Sculpture Park</a></li>
                                            <li><a className="seattle-link" href='https://www.mlb.com/mariners/ballpark' target="_blank" rel="noopener noreferrer">T-Mobile Park</a> (with Mariners games <a className="seattle-link" href='https://www.espn.com/mlb/team/schedule/_/name/sea/seasontype/2/half/2' target="_blank" rel="noopener noreferrer">earlier that week</a>)</li>
                                            <li><a className="seattle-link" href='https://www.lumenfield.com/' target="_blank" rel="noopener noreferrer">Lumen Field</a></li>
                                            <li><a className="seattle-link" href='https://fremont.com/explore/sights/troll/' target="_blank" rel="noopener noreferrer">Fremont Troll</a></li>
                                            <li><a className="seattle-link" href='https://visitseattle.org/blog/how-to-go-kayaking-in-seattles-lake-union/' target="_blank" rel="noopener noreferrer">Lake Union Kayaking</a></li>
                                        </ul>
                                    </p>
                                </Col>
                            </Row>
                            <br />
                            <hr />
                            <br />
                            <Row>
                                <Col>
                                    <h4>Bars and Restaurants</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={4}>
                                    <h5>Unique Bars</h5>
                                    <p>
                                        <ul>
                                            <li><a className="seattle-link" href='http://www.somerandombar.com/' target="_blank" rel="noopener noreferrer">Some Random Bar</a></li>
                                            <li><a className="seattle-link" href='https://www.canonseattle.com/' target="_blank" rel="noopener noreferrer">Canon</a></li>
                                            <li><a className="seattle-link" href='http://www.unicornseattle.com/' target="_blank" rel="noopener noreferrer">Unicorn </a></li>
                                            <li><a className="seattle-link" href='https://flatstickpub.com/' target="_blank" rel="noopener noreferrer">Flatstick Pub</a></li>
                                            <li><a className="seattle-link" href='https://add-a-ball.com/' target="_blank" rel="noopener noreferrer">Add-A-Ball</a></li>
                                            <li><a className="seattle-link" href='https://www.pineboxbar.com/about' target="_blank" rel="noopener noreferrer">The Pine Box</a></li>
                                            <li><a className="seattle-link" href='https://www.tavernlaw.com/needle-thread' target="_blank" rel="noopener noreferrer">Needle and Thread</a></li>
                                            <li><a className="seattle-link" href='https://www.nadimama.com/mbar' target="_blank" rel="noopener noreferrer">MBAR</a></li>
                                            <li><a className="seattle-link" href='https://www.purplecafe.com/seattle/' target="_blank" rel="noopener noreferrer">Purple Seattle</a></li>
                                            <li><a className="seattle-link" href='https://www.capitolcider.com/drink' target="_blank" rel="noopener noreferrer">Capitol Cider</a></li>
                                            <li><a className="seattle-link" href='https://www.flightwineandchocolate.com/' target="_blank" rel="noopener noreferrer">Flight Wine + Chocolate</a></li>
                                        </ul>
                                    </p>
                                </Col>
                                <Col xs={12} sm={6} md={8}>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <h5>Seattle Food Staples</h5>
                                            <p>
                                                <ul>
                                                    <li><a className="seattle-link" href='https://skilletfood.com/restaurant/capitol-hill/' target="_blank" rel="noopener noreferrer">Skillet</a></li>
                                                    <li><a className="seattle-link" href='https://www.thepinkdoor.net/' target="_blank" rel="noopener noreferrer">The Pink Door</a></li>
                                                    <li><a className="seattle-link" href='https://shiros.com/' target="_blank" rel="noopener noreferrer">Shiro's Sushi</a></li>
                                                    <li><a className="seattle-link" href='https://biscuitbitch.square.site/home' target="_blank" rel="noopener noreferrer">Biscuit Bitch</a></li>
                                                    <li><a className="seattle-link" href='https://thewalrusbar.com/' target="_blank" rel="noopener noreferrer">The Walrus and the Carpenter</a></li>
                                                    <li><a className="seattle-link" href='https://www.ddir.com/' target="_blank" rel="noopener noreferrer">Dick's Drive-In</a></li>
                                                    <li><a className="seattle-link" href='https://www.taylorshellfishfarms.com/location/capitol-hill-melrose' target="_blank" rel="noopener noreferrer">Taylor Shellfish</a></li>
                                                </ul>
                                            </p>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <h5>Fancy Dining</h5>
                                            <p>
                                                <ul>
                                                    <li><a className="seattle-link" href='https://canlis.com/' target="_blank" rel="noopener noreferrer">Canlis</a></li>
                                                    <li><a className="seattle-link" href='http://spinasse.com/' target="_blank" rel="noopener noreferrer">Spinasse</a></li>
                                                    <li><a className="seattle-link" href='http://www.edenhillrestaurant.com/' target="_blank" rel="noopener noreferrer">Eden Hill</a></li>
                                                    <li><a className="seattle-link" href='https://restaurantbateau.com/' target="_blank" rel="noopener noreferrer">Bateau</a></li>
                                                    <li><a className="seattle-link" href='https://sushikashiba.com/' target="_blank" rel="noopener noreferrer">Sushi Kashiba</a></li>
                                                </ul>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            <hr />
                            <br />
                            <Row>
                                <Col>
                                    <h4>Alcohol</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={4}>
                                    <h5>Breweries</h5>
                                    <p>
                                        <ul>
                                            <li><a className="seattle-link" href='https://www.elysianbrewing.com/' target="_blank" rel="noopener noreferrer">Elysian Brewing</a></li>
                                            <li><a className="seattle-link" href='https://redhook.com/breweries-pubs/capitol-hill' target="_blank" rel="noopener noreferrer">Redhook Brewlab</a></li>
                                            <li><a className="seattle-link" href='https://www.fremontbrewing.com/' target="_blank" rel="noopener noreferrer">Fremont Brewing</a></li>
                                            <li><a className="seattle-link" href='https://reubensbrews.com/' target="_blank" rel="noopener noreferrer">Reuben's Brews</a></li>
                                            <li><a className="seattle-link" href='https://optimismbrewing.com/' target="_blank" rel="noopener noreferrer">Optimism</a></li>
                                            <li><a className="seattle-link" href='https://cloudburstbrew.com/' target="_blank" rel="noopener noreferrer">Cloudburst Brewing</a></li>
                                            <li><a className="seattle-link" href='https://www.georgetownbeer.com/' target="_blank" rel="noopener noreferrer">Georgetown</a></li>
                                            <li><a className="seattle-link" href='https://www.pikebrewing.com/' target="_blank" rel="noopener noreferrer">Pike Brewing</a></li>
                                            <li><a className="seattle-link" href='https://www.seapinebrewing.com/' target="_blank" rel="noopener noreferrer">Seapine Brewing</a></li>
                                            <li><a className="seattle-link" href='https://www.twobeersbrewery.com/home/' target="_blank" rel="noopener noreferrer">Two Beers</a></li>
                                            <li><a className="seattle-link" href='https://www.stoupbrewing.com/' target="_blank" rel="noopener noreferrer">Stoup Brewing</a></li>
                                            <li><a className="seattle-link" href='https://holymountainbrewing.com/' target="_blank" rel="noopener noreferrer">Holy Mountain Brewing</a></li>
                                        </ul>
                                    </p>
                                </Col>
                                <Col xs={12} sm={6} md={8}>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <h5>Distilleries</h5>
                                            <p>
                                                <ul>
                                                    <li><a className="seattle-link" href='https://copperworksdistilling.com/' target="_blank" rel="noopener noreferrer">Copperworks Distilling Co.</a></li>
                                                    <li><a className="seattle-link" href='https://heritagedistilling.com/' target="_blank" rel="noopener noreferrer">Heritage Distilling Co</a></li>
                                                    <li><a className="seattle-link" href='https://www.ooladistillery.com/' target="_blank" rel="noopener noreferrer">OOLA Distillery</a></li>
                                                    <li><a className="seattle-link" href='http://batch206.com/' target="_blank" rel="noopener noreferrer">Batch 206</a></li>
                                                    <li><a className="seattle-link" href='https://www.2barspirits.com/' target="_blank" rel="noopener noreferrer">2BAR</a></li>
                                                    <li><a className="seattle-link" href='https://westlanddistillery.com/' target="_blank" rel="noopener noreferrer">Westland Distillery</a></li>
                                                    <li><a className="seattle-link" href='https://www.letterpressdistilling.com/' target="_blank" rel="noopener noreferrer">Letterpress Distilling</a></li>
                                                </ul>
                                            </p>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <h5>Cider</h5>
                                            <p>
                                                <ul>
                                                    <li><a className="seattle-link" href='https://www.schillingciderhousesea.com/' target="_blank" rel="noopener noreferrer">Schilling Cider House</a></li>
                                                    <li><a className="seattle-link" href='https://www.seattlecidercompany.com/' target="_blank" rel="noopener noreferrer">Seattle Cider Co</a></li>
                                                    <li><a className="seattle-link" href='https://www.locustcider.com/home/' target="_blank" rel="noopener noreferrer">Locust Cider</a></li>
                                                </ul>
                                            </p>
                                            <h5>Seltzer</h5>
                                            <p>
                                                <ul>
                                                    <li><a className="seattle-link" href='https://sanjuanseltzery.com/' target="_blank" rel="noopener noreferrer">San Juan Seltzer</a></li>
                                                </ul>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}