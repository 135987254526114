const NavLinks = {
    Home: '/',
    Story: '/story',
    Gallery: '/gallery',
    EngagementGallery: '/engagementgallery',
    EventDetails: '/eventdetails',
    SeattleActivities: '/seattle',
    Travel: '/travel',
    Rsvp: '/rsvp',
    Registry: '/registry',
}

export default NavLinks;