import * as React from 'react';
import './RegistryView.css';

export default class RegistryView extends React.Component<any, any> {
    componentDidMount() {
        const script = document.createElement("script");
        script.innerHTML = '!function(e,t,n){var s,a=e.getElementsByTagName(t)[0];e.getElementById(n)||(s=e.createElement(t),s.id=n,s.async=!0,s.src="https://widget.zola.com/js/widget.js",a.parentNode.insertBefore(s,a))}(document,"script","zola-wjs");'
        script.async = true;
        var registryDiv = document.getElementsByClassName("page");
        if (registryDiv)
            registryDiv[0].appendChild(script);
    }

    public render() {

        return (
            <a className="zola-registry-embed" href="www.zola.com/registry/cooperandshelby2022" data-registry-key="cooperandshelby2022">Our Zola Wedding Registry</a>
        );
    }
}