import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Container from 'react-bootstrap/esm/Container';
import './GalleryView.css';
import Photos from '../components/Photos';

const GalleryView = (props: any) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div>
            <Container className="gallery">
                <br />
                <h1 className="gallery-header" >Photo Gallery</h1>
                <br />
            </Container>
            <Gallery direction='column' photos={Photos} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={Photos.map(x => ({
                                ...x,
                                source: x.src,
                                caption: x.caption
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}

export default GalleryView;