import * as React from 'react';
import './HomeView.css';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

export default class HomeView extends React.Component<any, any> {

    public render() {

        return (
            <div className="home-fill">
                <Jumbotron fluid className="homepage-jumbotron" >
                    <Container className="jumbotron-text">
                        <Container>
                            <h1 className="justify-content-center homepage-title">Shelby and Cooper</h1>
                            <br />
                            <h1 className="justify-content-center homepage-date">8/13/2022</h1>
                        </Container>
                    </Container>
                </Jumbotron>
            </div>
        );
    }
}