const Photos = [
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/1.jpg",
        width: 3,
        height: 4,
        caption: "2019 Vancouver, BC: Vancouver Canucks vs. NY Islanders"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/21.jpg",
        width: 4,
        height: 4,
        caption: "2019 Napa Valley, CA: Bottlerock Festival"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/22.jpg",
        width: 3,
        height: 4,
        caption: "2018 Seattle, WA: Paint and Sip Night"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/11.jpg",
        width: 4,
        height: 3,
        caption: "2018 Seattle, WA: Lake Union Kayaking"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/16.jpg",
        width: 3,
        height: 4,
        caption: "2018 Seattle, WA: Seattle Mariners Firework Night on Safeco Field"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/24.jpg",
        width: 4,
        height: 4,
        caption: "2019 Seattle, WA: MLS Cup Sounders FC vs. Toronto FC"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/2.jpg",
        width: 3,
        height: 4,
        caption: "2017 Woodinville, WA: Birthday Wine Tour"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/4.jpg",
        width: 3,
        height: 3,
        caption: "2019 Orlando, FL: Disney World"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/3.jpg",
        width: 3,
        height: 4,
        caption: "2017 Seattle, WA: Freaknight Music Feastival"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/5.jpg",
        width: 4,
        height: 4,
        caption: "2018 Seattle, WA: Seattle Mariners Bark in the Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/6.jpg",
        width: 3,
        height: 4,
        caption: "2018 Seattle, WA: Alki Beach Roller Skating"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/7.jpg",
        width: 3,
        height: 4,
        caption: "2019 Chicago, IL: Scott and Jordan's Wedding"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/8.jpg",
        width: 4,
        height: 4,
        caption: "2018 Snoqualmie Pass, WA: Snowboarding at Snoqualmie"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/9.jpg",
        width: 4,
        height: 4,
        caption: "2017 Woodinville, WA: Birthday Wine Tour"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/10.jpg",
        width: 4,
        height: 4,
        caption: "2017 Carlisle, PA: Jeff and Laura's Wedding"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/14.jpg",
        width: 4,
        height: 3,
        caption: "2017 Seattle, WA: Christmas Cards"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/12.jpg",
        width: 3,
        height: 4,
        caption: "2020 Charlotte, NC: Aly and Garrett's Wedding"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/13.jpg",
        width: 4,
        height: 3,
        caption: "2018 Seattle, WA: Seattle Mariners Bark in the Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/15.jpg",
        width: 3,
        height: 4,
        caption: "2019 Napa Valley, CA: Robert Mondavi Winery"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/17.jpg",
        width: 4,
        height: 4,
        caption: "2018 Big Island, HI: Mike and Amanda's Wedding"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/18.jpg",
        width: 4,
        height: 3,
        caption: "2018 Seattle, WA: Paint and Sip Night"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/19.jpg",
        width: 3,
        height: 4,
        caption: "2017 Mechanicsburg, PA: Before Jeff and Laura's Wedding"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/20.jpg",
        width: 3,
        height: 4,
        caption: "2017 San Luis Obispo, CA: At the Beach"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/25.jpg",
        width: 3,
        height: 4,
        caption: "2019 Chicago, IL: Wrigley Field"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/26.jpg",
        width: 3,
        height: 4,
        caption: "2018 Seattle, WA: Greenlake"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/27.jpg",
        width: 4,
        height: 3,
        caption: "2017 San Luis Obispo, CA: At the Beach"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/28.jpg",
        width: 3,
        height: 4,
        caption: "2018 Big Island, HI: Kohala Mountains Hike"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/gallery/29.jpg",
        width: 4,
        height: 3,
        caption: "2021 Seattle, WA: Lumen Field - Field to Table"
    }
];

export default Photos;