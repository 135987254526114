import * as React from 'react';
import './RsvpView.css';

export default class RsvpView extends React.Component<any, any> {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://shelbycooperswedding.rsvpify.com/embed";
        script.async = true;
        var rsvpdiv = document.getElementsByClassName("page");
        if(rsvpdiv)
            rsvpdiv[0].appendChild(script);
    }

    componentDidUnmount() {
        let allsuspects = document.getElementsByTagName("script");
        for (let i = allsuspects.length; i >= 0; i--) {
            if (allsuspects[i]){
                if(allsuspects[i].getAttribute("src") !== null){
                    var suspectSrc = allsuspects[i].getAttribute("src");
                    if (suspectSrc != null) {
                        if (suspectSrc?.indexOf("https://shelbycooperswedding.rsvpify.com/embed") !== -1) {
                            var suspectNodeParentNode = allsuspects[i].parentNode;    
                            if (suspectNodeParentNode != null)
                            {
                                suspectNodeParentNode.removeChild(allsuspects[i]);
                            }
                        }
                    }
                }
            }
        }
    }

    public render() {

        return (
            <div id="rsvp" className="rsvp-fill">
            </div>
        );
    }
}