import * as React from 'react';
import './StoryView.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';

export default class StoryView extends React.Component<any, any> {

    public render() {

        return (
            <div className="story-fill">
                <Container className="story-container">
                    <br />
                    <Row >
                        <Col>
                            <h1 className="story-header">How We Met</h1>
                            <h4 className="story-date">3/6/2017</h4>
                            <br />
                            <Row className="align-items-center">
                                <Col xl={3} className="story-picture-div" >
                                    <Image className="howwemet-picture" src="https://shelbycooperwedding.blob.core.windows.net/images/home/Cooper-Shelby-Volleyball-Square.jpg" alt="Cooper-Shelby" roundedCircle fluid />
                                </Col>
                                <Col xl={9}>
                                    <p className="story-text">
                                        It all started at church.
                                        Well... it started at *A* church. During an adult, drunk volleyball league in March of 2017.
                                        Shelby was called in to sub on a friend’s team for the league’s championship games.
                                        Cooper’s team made it to the semi-finals, but they were no match for the team with a (technically illegitimate) sub.
                                        Final score: Shelby 3 sets, Cooper 0.
                                        That could have easily been their last encounter, but as fate would have it, Shelby was called in to sub again just a couple days later on a team that Cooper had just joined.
                                        It was clear their volleyball skills impressed each other, and the two hit it off after the game over a few margaritas and a couple toasted grasshoppers at Poquitos.
                                        Their first official date was the following Monday, at Some Random Bar in Belltown, right across the street from Shelby’s apartment. That made it convenient for Cooper to meet Shelby’s 6-month-old puppy Dakota that night.
                                        From then on, Cooper and Dakota have been inseparable. And Shelby isn’t a bad third wheel to have tag along.
                                        <br/>
                                        Baker is also here. 
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <br />
                    <hr />
                    <br />
                    <Row>
                        <Col>
                            <h1 className="story-header">The Proposal</h1>
                            <h4 className="story-date">3/6/2021</h4>
                            <br />
                            <Row className="align-items-center">
                                <Col xl={9}>
                                    <p className="story-text">
                                        Four years later, Cooper and Shelby went on an anniversary picnic at the Olympic Sculpture Park. After a quick lunch, they went on a walk along the water in one of the most beautiful spots in the city on a rare, sunny March day.
                                        Cooper popped the question on a quiet, intimate bench right along the waterfront. Of course, Shelby said yes.
                                        They went home to let their families and the dogs know, but not before stopping to grab a bottle of champagne to celebrate.
                                    </p>
                                </Col>
                                <Col xl={3} className="story-picture-div" >
                                    <Image className="howwemet-picture" src="https://images.unsplash.com/photo-1574097810902-77e848caba8a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80" alt="Cooper-Shelby" roundedCircle fluid />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}