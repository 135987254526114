import * as React from 'react';
import './Navbar.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default class NavbarComponent extends React.Component<any, any> {

    public render() {
        return (
            <Navbar expand="lg" className="custom-navbar">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link className="custom-navlink" href="/">Home</Nav.Link>
                        <Nav.Link className="custom-navlink" href="/Story">Our Story</Nav.Link>
                        <NavDropdown className="custom-navlink" title="Photos" id="details-dropdown">
                            <NavDropdown.Item className="custom-navlink" href="/EngagementGallery">Engagement Gallery</NavDropdown.Item>
                            <NavDropdown.Item className="custom-navlink" href="/Gallery">Gallery</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown className="custom-navlink" title="Details" id="details-dropdown">
                            <NavDropdown.Item className="custom-navdropdown-item" href="/EventDetails">Event Details</NavDropdown.Item>
                            <NavDropdown.Item className="custom-navdropdown-item" href="/Travel">Travel</NavDropdown.Item>
                            <NavDropdown.Item className="custom-navdropdown-item" href="/Seattle">Seattle Activities</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link className="custom-navlink" href="/Rsvp">RSVP</Nav.Link>
                        <Nav.Link className="custom-navlink" href="/Registry">Registry</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}