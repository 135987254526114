import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import Routes from './Routes';
import NavbarComponent from './shell/Navbar';
import FooterComponent from './shell/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <NavbarComponent />
          <div className="page">
            <Routes />
          </div>
          <FooterComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
