import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import NavLinks from './components/NavLinks';
import HomeView from './views/HomeView';
import StoryView from './views/StoryView';
import GalleryView from './views/GalleryView';
import EngagementGalleryView from './views/EngagementGalleryView';
import EventDetailsView from './views/EventDetailsView';
import TravelView from './views/TravelView';
import SeattleActivitiesView from './views/SeattleActivitiesView';
import RsvpView from './views/RsvpView';
import RegistryView from './views/RegistryView';

export default (props: any) => {
    return (
        <Switch>
            <Route path={`${NavLinks.Home}`} exact component={HomeView} />
            <Route path={`${NavLinks.Story}`} exact component={StoryView} />
            <Route path={`${NavLinks.EngagementGallery}`} exact component={EngagementGalleryView} />
            <Route path={`${NavLinks.Gallery}`} exact component={GalleryView} />
            <Route path={`${NavLinks.EventDetails}`} exact component={EventDetailsView} />
            <Route path={`${NavLinks.Travel}`} exact component={TravelView} />
            <Route path={`${NavLinks.SeattleActivities}`} exact component={SeattleActivitiesView} />
            <Route path={`${NavLinks.Rsvp}`} exact component={RsvpView} />
            <Route path={`${NavLinks.Registry}`} exact component={RegistryView} />
            <Route component={HomeView} />
        </Switch>
    )
}