const EngagementPhotos = [
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e1.jpg",
        width: 3,
        height: 2,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e2.jpg",
        width: 2,
        height: 3,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e20.jpg",
        width: 2,
        height: 3,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e3.jpg",
        width: 3,
        height: 2,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e4.jpg",
        width: 3,
        height: 2,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e5.jpg",
        width: 2,
        height: 3,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e6.jpg",
        width: 2,
        height: 3,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e7.jpg",
        width: 3,
        height: 2,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e8.jpg",
        width: 2,
        height: 3,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e9.jpg",
        width: 2,
        height: 3,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e10.jpg",
        width: 3,
        height: 2,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e11.jpg",
        width: 2,
        height: 3,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e12.jpg",
        width: 2,
        height: 3,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e13.jpg",
        width: 3,
        height: 2,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e14.jpg",
        width: 2,
        height: 3,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e15.jpg",
        width: 3,
        height: 2,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e16.jpg",
        width: 2,
        height: 3,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e17.jpg",
        width: 3,
        height: 2,
        caption: "2022 Olympic Sculpture Park"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e18.jpg",
        width: 3,
        height: 2,
        caption: "2022 University of Washington"
    },
    {
        src: "https://shelbycooperwedding.blob.core.windows.net/images/gallery/engagement/e19.jpg",
        width: 3,
        height: 2,
        caption: "2022 University of Washington"
    }
];

export default EngagementPhotos;