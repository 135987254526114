import * as React from 'react';
import './EventDetailsView.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class EventDetailsView extends React.Component<any, any> {

    public render() {

        return (
            <div className="event-details-fill">
                <Container>
                    <br />
                    <Row >
                        <Col>
                            <h1 className="event-details-header">Event Details</h1>
                            <h4 className="event-details-text">Welcome Party</h4>
                            <h2 className="event-details-date">Friday August 12, 2022</h2>
                            <h2 className="event-details-time">5-8PM</h2>
                            <h4 className="event-details-location"><a className="event-details-link" href='https://optimismbrewing.com/' target="_blank" rel="noopener noreferrer">Optimism Brewing Company</a></h4>
                            <h4 className="event-details-address"><a className="event-details-link" href='https://www.google.com/maps/place/Optimism+Brewing+Company/@47.6128,-122.3227267,17z/data=!3m1!4b1!4m5!3m4!1s0x54906acc07f09b89:0xaec46580d07fc936!8m2!3d47.6128!4d-122.3205327' target="_blank" rel="noopener noreferrer">1158 Broadway, Seattle, WA 98122</a></h4>
                            <h4><i>Beer and light snacks</i></h4>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row >
                        <Col>
                            <h4 className="event-details-text">Wedding</h4>
                            <h2 className="event-details-date">Saturday August 13, 2022</h2>
                            <h2 className="event-details-time">5PM</h2>
                            <h4 className="event-details-location"><a className="event-details-link" href='https://www.withinsodo.com/' target="_blank" rel="noopener noreferrer">Within SODO</a></h4>
                            <h4 className="event-details-address"><a className="event-details-link" href='https://www.google.com/maps/place/WithinSodo/@47.5771676,-122.3370983,17z/data=!3m2!4b1!5s0x5490402ee74951ad:0x3493611fc51fa265!4m5!3m4!1s0x5490419b2aa64695:0xa65e1891b4b5137e!8m2!3d47.5771676!4d-122.3349043' target="_blank" rel="noopener noreferrer">2916 UTAH AVE S. SEATTLE, WA</a></h4>
                            <h4><i>Semi-formal Cocktail Attire</i></h4>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}